import { PatientDetails } from "@cur8/api-client/dist/api/cur8/generated-types";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

interface props {
  patientId: string;
}

export default function PatientInfo({ patientId }: props) {
  const api = useAPIClient().patient;

  const [patientDetails, setPatientDetails] = useState<PatientDetails>();

  useEffect(() => {
    api.getPatientDetails({ patientId }).result.then((patient) => {
      setPatientDetails(patient);
    });
  }, [api, patientId]);

  const displayName = patientDetails?.name?.displayName;
  const firstName = patientDetails?.name?.firstName;
  const lastName = patientDetails?.name?.lastName;
  const legacyName = patientDetails?.physicalIds[0].startsWith(
    "cur8:id:legacy:"
  )
    ? patientDetails.physicalIds[0].split(":")[3]
    : "no name";

  let name = legacyName;
  if (displayName) {
    name = displayName;
  } else if (firstName) {
    name = lastName ? firstName + " " + lastName : firstName;
  }

  return <div>{name}</div>;
}
